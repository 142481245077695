<template>
  <div class="app-container">
    <div class="filter-container">
      <template v-if="user.user.type === 'admin'">
        <el-select class="filter-item" filterable v-model="listQuery.school_id" placeholder="所属学校" style="width: 300px" clearable>
          <el-option v-for="item in schools" :key="item.id" :label="item.school_name" :value="item.id">
          </el-option>
        </el-select>
      </template>

      <template v-if="user.user.type !== 'shop' && user.user.type !== 'admin'">
        <el-select class="filter-item" multiple collapse-tags filterable v-model="listQuery.shop_ids" placeholder="所属店铺" style="width: 300px" clearable>
          <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
          </el-option>
        </el-select>
      </template>

      <el-date-picker class="filter-item" style="display: inline-flex;" v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>

    <div v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ data.total_turnover || 0 }}</div>
            <div class="subtitle">总营业额</div>
          </el-card>
        </el-col>
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ data.total_num || 0 }}</div>
            <div class="subtitle">总订单数</div>
          </el-card>
        </el-col>
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ data.customer_unit_price || 0 }}</div>
            <div class="subtitle">客单价</div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 20px;">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.yesterday_order_rate || 0 }}%</div>
            <div class="subtitle">昨天订单增长率</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.yesterday_turnover_rate || 0 }}%</div>
            <div class="subtitle">昨天营业额增长率</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.last_week_order_rate || 0 }}%</div>
            <div class="subtitle">上周订单增长率</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.last_week_turnover_rate || 0 }}%</div>
            <div class="subtitle">上周营业额增长率</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.last_month_order_rate || 0 }}%</div>
            <div class="subtitle">上月订单增长率</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.last_month_turnover_rate || 0 }}%</div>
            <div class="subtitle">上月营业额增长率</div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 20px;">
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.shop_total || 0 }}</div>
            <div class="subtitle">商家总数</div>
          </el-card>
        </el-col>
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.sendman_total || 0 }}</div>
            <div class="subtitle">配送总数</div>
          </el-card>
        </el-col>
        <el-col :sm="8">
          <el-card class="box-card">
            <div class="value">{{ orderTurnoverRate.user_total || 0 }}</div>
            <div class="subtitle">用户总数</div>
          </el-card>
        </el-col>
      </el-row>

      <div style="margin-top: 30px;display: flex;">
        <!-- 折线图 -->
        <ChartOrderTurnover style="width: 50%;" :data="data.order_turnover"></ChartOrderTurnover>
        <!-- 折线图 -->

        <!-- 饼图 -->
        <ChartUserGender style="width: 50%;" title="用户性别比例" seriesName="性别比例" :color='[
          "#909399",
          "#409EFF",
          "#F56C6C",
        ]' :data="orderTurnoverRate.user_gender_chart"></ChartUserGender>
        <!-- 饼图 -->
      </div>

      <div v-if="user.user.type === 'admin' && !listQuery.school_id">
        <el-table :data="orderTurnoverRate.school_order_list" element-loading-text="Loading" border fit highlight-current-row>
          <el-table-column label="排名" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column label="学校名称" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.school ? scope.row.school.school_name : "" }}
            </template>
          </el-table-column>

          <el-table-column label="订单数" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.order_total }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="user.user.type !== 'admin' || listQuery.school_id">
        <el-table :data="orderTurnoverRate.shop_order_list" element-loading-text="Loading" border fit highlight-current-row>
          <el-table-column label="排名" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column label="店铺名称" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.shop ? scope.row.shop.shop_name : "" }}
            </template>
          </el-table-column>

          <el-table-column label="订单数" min-width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.order_total }}
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 30px;display: flex;">
          <!-- 饼图 -->
          <ChartUserGender style="width: 50%;" title="食堂订单比例(前10)" seriesName="食堂订单比例(前10)" :data="orderTurnoverRate.canteen_order_chart"></ChartUserGender>
          <!-- 饼图 -->

          <!-- 饼图 -->
          <ChartUserGender style="width: 50%;" title="楼栋订单比例(前10)" seriesName="楼栋订单比例(前10)" :data="orderTurnoverRate.building_order_chart"></ChartUserGender>
          <!-- 饼图 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ChartOrderTurnover from '@/components/ChartOrderTurnover'
  import ChartUserGender from '@/components/ChartUserGender'
  import request from "@/utils/request";
  import {mapGetters} from "vuex";
  var moment = require("moment");

  export default {
    components: {
      ChartOrderTurnover,
      ChartUserGender,
    },
    data() {
      return {
        shops: [],
        listLoading:false,
        dateArr: null,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        listQuery: {
          school_id: "",
          shop_ids: [],
          start_time: "",
          end_time: "",
        },
        orderTurnoverRate: [],
        data: [],
      };
    },
    async created() {
      this.getShopList();
      // 默认近七天数据
      this.listQuery.start_time = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.listQuery.end_time = moment().format("YYYY-MM-DD");
      this.dateArr = [this.listQuery.start_time, this.listQuery.end_time];
      await this.getList();
      await this.getDataAnalysis();
    },
    computed: {
      ...mapGetters(["schools", "school_id","user"])
    },
    watch: {
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_time = newVal[0] + ' 00:00:00';
          this.listQuery.end_time = newVal[1] + ' 23:59:59';
        } else {
          this.listQuery.start_time = "";
          this.listQuery.end_time = "";
        }
      },
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getShopList();
      },
    },
    methods: {
      async getList() {
        this.listLoading = true
        return await request({
          url: "/api/backend/finance/turnoverRate",
          method: "post",
          data: this.listQuery,
          timeout: 10 * 60 * 1000,
        }).then(res => {
          this.orderTurnoverRate = res.data;
          this.listLoading = false
        });
      },
      async getDataAnalysis() {
        this.listLoading = true
        return await request({
          url: "/api/backend/finance/dataAnalysis",
          method: "post",
          data: this.listQuery,
          timeout: 10 * 60 * 1000,
        }).then(res => {
          this.data = res.data;
          this.listLoading = false
        });
      },
      getShopList() {
        request({
          url: "/api/backend/shop/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
          }
        }).then(response => {
          this.shops = response.data.data;
        });
      },
      async handleFilter() {
        if(!this.listQuery.start_time || !this.listQuery.end_time){
          this.$message.warning('请选择开始和结束日期')
          return
        }
        await this.getList();
        await this.getDataAnalysis();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
